import { axiosClient } from "./apiClient";

export function signUp(data){
    return axiosClient.post('/v1/customer/signup', data);
}

export function getCountryCode(data){
    return axiosClient.post('/v1/customer/getPhoneCode', data);
}

export function completeProfile(data){
    return axiosClient.post('/v1/customer/completeprofile', data);
}

export function login(data){
    return axiosClient.post('/v1/customer/login', data);
}

export function forgotPassword(data){
    return axiosClient.post('/v1/customer/forgotPassword', data);
}

export function verifyCode(data){
    return axiosClient.post('/v1/customer/checkcode', data);
}

export function changePassword(data){
    return axiosClient.post('/v1/customer/changePassword', data);
}

export function sendOTP(data){
    return axiosClient.post('/v1/customer/otp', data);
}

export function verifyOTP(data){
    return axiosClient.post('/v1/customer/verifyotp', data);
}

export function getNetwork(data){
    return axiosClient.get('v1/customer/getNetwork', data)
}

export function feedback(data){
    return axiosClient.post('/v1/customer/feedback', data);
}

export function getFeedback(){
    return axiosClient.get('/v1/customer/getfeedback');
}

export function modifyPassword(data){
    return axiosClient.post('/v1/customer/modifypassword', data);
}

export function contactUs(data){
    return axiosClient.post('/v1/customer/contactus', data);
}

export function showProfile(){
    return axiosClient.get('/v1/customer/profile');
}

export function editProfile(data){
    return axiosClient.post('/v1/customer/updateprofile', data);
}

export function addQuotes(data){
    return axiosClient.post('/v1/customer/addQuote', data)
}

export function editQuotes(data){
    return axiosClient.post('/v1/customer/editQuote', data)
}

export function getQuotes(data){
    return axiosClient.post('/v1/customer/getQuotes', data);
}

export function getPendingQuotes(data){
    return axiosClient.post('/v1/customer/getPendingQuotes', data);
}

export function getFilterQuotes(data){
    return axiosClient.post('/v1/customer/filterQuotes', data)
}

export function getQuoteDetail(data){
    return axiosClient.post('/v1/customer/getQuoteDetail', data);
}

export function quotesContact(data){
    return axiosClient.post('/v1/customer/quotesContact', data);
}

export function getQuotesContact(data){
    return axiosClient.post('/v1/customer/getQuotesContact', data);
}

export function getShipments(data){
    return axiosClient.post('/v1/customer/getshipmentlist', data);
}

export function getActiveShipment(data){
    return axiosClient.post('/v1/customer/getactiveshipment', data);
}

export function getShipmentDetail(data){
    return axiosClient.post('/v1/customer/getShipmentDetail', data);
}

export function getShipmentInvoiceList(data){
    return axiosClient.post('/v1/customer/getShipmentInvoiceList', data);
}

export function getTrackingShipmentDetails(data){
    return axiosClient.post('/v1/customer/getShipmentTrackingDetail', data);
}

export function getDocuments(data){
    return axiosClient.post('/v1/customer/getDocuments', data);
}

export function getFilterShipments(data){
    return axiosClient.post('/v1/customer/filterShipments', data);
}

export function getAlert(data){
    return axiosClient.post('/v1/customer/getAlert', data);
}

export function getAlertNo(data){
    return axiosClient.post('/v1/customer/getAlertCount', data);
}

export function getFilterAlerts(data){
    return axiosClient.post('/v1/customer/filterAlerts', data)
}

export function search(data){
    return axiosClient.post('/v1/customer/search', data)
}

export function mainSearch(data){
    return axiosClient.post('v1/customer/mainSearch', data)
}

export function mainFilter(data){
    return axiosClient.post('v1/customer/mainFilter', data)
}

export function getAbout(data){
    return axiosClient.get('v1/customer/getAbout', data)
}

export function getPolicy(data){
    return axiosClient.get('v1/customer/getPolicy', data)
}

export function getModuleCount(data){
    return axiosClient.post('/v1/customer/getCount', data);
}

export function logout(){
    return axiosClient.post('/v1/customer/logout');
}
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getFilterShipments, getShipments, search } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { parse, isDate } from "date-fns";
import TablePagination from '@material-ui/core/TablePagination';
import { useTranslation } from "react-i18next";

export default function Shipment() {
    let navigate = useNavigate(); 
    var fileDownload = require('js-file-download');
    const commonProps = {title: "shipment"}

    var [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t, i18n } = useTranslation();


    useEffect(() => {
        filterData({});
    }, []);

    function filterData(data){
        getShipments(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }
            else{
                setData([]);
            }
        });
    }

    function parseDateString(value, originalValue) {
        const parsedDate = isDate(originalValue)
          ? originalValue
          : parse(originalValue, "yyyy-MM-dd", new Date());
        return originalValue;
    }

    const validationSchema = Yup.object().shape({
        start_date: Yup.string().nullable().notRequired(),
        end_date: Yup.string().transform(parseDateString).notRequired(),
        origin: Yup.string(),
        destination: Yup.string()
    });
    
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
       getFilterShipments(data).then((response) => {
           response = JSON.parse(response);
           if(response.code == 1){
               setData(response.data);
           }
           else{
               console.log("null");
           }
       });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const timeConvert = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    if (data == null) return <></>

    return (
        <>
            <Helmet>
                <title>{t('shipment')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <section className="dashboard_area">
                                    <div className="row align-items-center quo_area">
                                        <div className="col-12 col-xl-9 col-lg-7 col-md-7 col-sm-12 d-flex ps-0">
                                            <div className="q_drop">
                                                <ul className="quotes_drop quotes_drop_f6 mb-0">
                                                    <li className="d-flex align-items-center show_block">
                                                        <h6 className="s_o_padding">{t('show_only')}</h6>
                                                        <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"cargo_type": ["Air Export", "Air Import"]})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} alt="plane" /> {t('air')} <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"cargo_type": ["Sea Export", "Sea Import"]})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="boat" />{t('sea')} <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"cargo_type": ["Miscellaneous"]})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="truck" />{t('other')} <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"nestatus":"In transit"})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/responded-blue.svg"}
                                                                alt="respond" />In Transit
                                                            <i className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"status":"Delivered"})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/pending-blue.svg"} alt="pending" />Delivered
                                                            <i className="fa-solid fa-circle-check"></i> </a>
                                                        {/* <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"status":"Out of delivery"})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/expired-blue.svg"} alt="expire" />{t('dispatch')}
                                                            <i className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"status":"Arrived"})}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/bell-notification.svg"} alt="expire" />{t('arrived')}
                                                            <i className="fa-solid fa-circle-check"></i> </a> */}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle text_35 pt-0" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {t('filter')} <img src="assets/images/icon/down-arrow.svg" alt="" />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                                        <li>
                                                            <span className="dropdown-item">
                                                                <span>{t('select_date')}</span>
                                                                <div className="quote_dropdown">
                                                                    <p className="mb-0">{t('from')}:</p>
                                                                    <input type="date" id="birthday" name="birthday"
                                                                        className="date_input" {...register('start_date')} />
                                                                    <div className="invalid-feedback">{errors.startdate?.message}</div>
                                                                </div>
                                                                <div className="quote_dropdown">
                                                                    <p className="mb-0">{t('to')}:</p>
                                                                    <input type="date" id="birthday" name="birthday"
                                                                        className="date_input" {...register('end_date')} />
                                                                    <div className="invalid-feedback">{errors.enddate?.message}</div>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li className="mt-2">
                                                            <span className="dropdown-item quote_origin">
                                                                <div className="input_box">
                                                                    <input type="text" className="form-control" placeholder={t('origin')}
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        required="required" {...register('origin')} />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                                    <div className="invalid-feedback">{errors.origin?.message}</div>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li className="mt-2">
                                                            <span className="dropdown-item quote_origin">
                                                                <div className="input_box">
                                                                    <input type="text" className="form-control"
                                                                        placeholder={t('destination')} id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" required="required" {...register('destination')} />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                                    <div className="invalid-feedback">{errors.destination?.message}</div>
                                                                </div>
                                                            </span>
                                                        </li>
                                                        <li className="quote_apply mt-2">
                                                            <button type="submit" className="dropdown-item btn btn_quote_apply">{t('apply')}</button>
                                                        </li>
                                                    </form>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-10 ms-auto">
                                            <div className="header_search">
                                                <div className="input-group">
                                                    <span type="button" className="input-group-text">
                                                        <img src="assets/images/icon/search.svg" alt="search" />
                                                    </span>
                                                    <input type="text" id="ssearch" className="form-control" placeholder={t('search')} onChange={(e) => filterData({"search" : e.target.value})} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="quote_table table-responsive">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr className="table_header_bg">
                                                    <th scope="col">{t('our_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('your_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('destination')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('cargo_type')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">AWB/BL<img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">Status Date <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('status')}</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            {data.length > 0 ?
                                                <tbody>
                                                    {data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((shipmentdata, key) => {
                                                        return(
                                                            <>
                                                                <tr className="table-row" key={key} onClick={e => {
                                                                    if (e.target.id === 'track-parcel' || e.target.id === 'contract') {
                                                                        return;
                                                                    }
                                                                    else{
                                                                        navigate(`/shipment-tab/${shipmentdata._id}`);
                                                                    }
                                                                    }}>
                                                                    <td>
                                                                        {shipmentdata.type.split(" ")[0] == "Air" ? 
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="plane" />
                                                                        : shipmentdata.type.split(" ")[0] == "Sea" ?
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-1" alt="ship" />
                                                                        :
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-1" alt="truck" />
                                                                        }
                                                                        {shipmentdata.our_reference}
                                                                    </td>
                                                                    <td>{shipmentdata.customer_reference}</td>
                                                                    <td>{timeConvert(shipmentdata.date)}</td>
                                                                    <td>{shipmentdata.origin}</td>
                                                                    <td>{shipmentdata.destination}</td>
                                                                    <td>{shipmentdata.cargo_type}</td>
                                                                    <td>{shipmentdata.mawb != undefined ? shipmentdata.mawb : shipmentdata.mbl != undefined ? shipmentdata.mbl : shipmentdata.ref}</td>
                                                                    <td><span>{shipmentdata.tracking_date != undefined ? timeConvert(shipmentdata.tracking_date) : "-- /-- /--"}</span></td>
                                                                    <td>
                                                                        {shipmentdata.status != "" && shipmentdata.status != undefined ?
                                                                            <span className="table_respond inhe_table">{shipmentdata.status}</span>
                                                                        :
                                                                            <></>
                                                                        }
                                                                    </td>
                                                                    <td><Link id={"track-parcel"} to={`/track-parcel/${shipmentdata._id}`} className="btn_view">Track parcel</Link> </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="13">
                                                            <div className="folder_image m-auto text-center">
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                            </div>
                                                            <div className="folder_image m-auto text-center">
                                                                <p className="folder_grey">{t('no_data')}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    <div className="quote_pagination">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <TablePagination 
                                                    className='page-item quote_pre'
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    component="div"
                                                    count={data.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </ul>
                                        </nav>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getAlert, getFilterAlerts } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import TablePagination from '@material-ui/core/TablePagination';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parse, isDate } from "date-fns";
import { useTranslation } from "react-i18next";

export default function Alert() {
    const commonProps = {title: "home"}

    var [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        filterData({});
    }, []);

    function filterData(data){
        getAlert(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }
            else{
                console.log("null");
            }
        });
    }

    function parseDateString(value, originalValue) {
        const parsedDate = isDate(originalValue)
          ? originalValue
          : parse(originalValue, "yyyy-MM-dd", new Date());
        return originalValue;
    }

    const validationSchema = Yup.object().shape({
        start_date: Yup.string().transform(parseDateString).notRequired(),
        end_date: Yup.string().transform(parseDateString).notRequired(),
        origin: Yup.string(),
        destination: Yup.string()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        console.log(data)
        getFilterAlerts(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }
            else{
                console.log("null");
            }
        });
    }

    var flagForArrowDown = true;
    function arrowDown(){
        let img = document.getElementById('arrow');
        if(flagForArrowDown){
            console.log(flagForArrowDown);
            img.classList.add('ex_change');
            flagForArrowDown = !flagForArrowDown;
        }
        else{
            img.classList.remove('ex_change');
            flagForArrowDown = !flagForArrowDown;
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Helmet>
                <title>{t('alert')}</title>
            </Helmet>
            <main className="dash_b h-100">
                <div className="container-fluid h-100">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps} />

                        <section id="content-wrapper" className="h-100">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="back_alert">
                                    <div className="back_shipment">
                                        <Link to="/">
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </Link>
                                        <h4>{t('back')}</h4>
                                    </div>
                                    <h4 className="mt-4">{t('alert')} {t('and')} {t('tasks')}</h4>
                                </div>
                                <div className="row align-items-center quo_area">
                                    <div className="col-12 col-xl-9 col-lg-7 col-md-7 col-sm-12 d-flex ps-0">
                                        <div className="q_drop">
                                            <ul className="quotes_drop quotes_drop_f6 mb-0">
                                                <li className="d-flex align-items-center show_block">
                                                    <h6 className="s_o_padding">{t('show_only')}</h6>
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"cargo_type":"Air"})}><img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} alt="plane" /> {t('air')} <i
                                                            className="fa-solid fa-circle-check"></i></a>
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"cargo_type":"Sea"})}><img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="boat" />{t('sea')} <i
                                                            className="fa-solid fa-circle-check"></i> </a>
                                                    {/* <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"cargo_type":"Others"})}><img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="truck" />{t('road')} <i
                                                            className="fa-solid fa-circle-check"></i> </a> */}
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"status":"Review Invoice"})}><img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/responded-blue.svg"} alt="respond" />{t('responded')}
                                                        <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"status":"Missing Document"})}><img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/pending-blue.svg"} alt="pending" />{t("pending")} <i
                                                            className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => filterData({"status":"Alert"})}><img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/expired-blue.svg"} alt="expire" />{t('expired')} <i
                                                            className="fa-solid fa-circle-check"></i> </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle text_35 pt-0" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                {t('filter')} <img src="assets/images/icon/down-arrow.svg" alt="" />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                                    <li>
                                                        <span className="dropdown-item">
                                                            <span>{t('select_date')}</span>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('from')}:</p>
                                                                <input type="date" id="birthday" name="birthday" className="date_input" {...register('start_date')} />
                                                            </div>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('to')}:</p>
                                                                <input type="date" id="birthday" name="birthday" className="date_input" {...register('end_date')} />
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="mt-2">
                                                        <span className="dropdown-item quote_origin">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder={t('origin')} {...register('origin')}
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="mt-2">
                                                        <span className="dropdown-item quote_origin">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder={t('destination')} {...register('destination')}
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="quote_apply mt-2">
                                                        <button type='submit' className="dropdown-item btn btn_quote_apply">{t('apply')}</button>
                                                    </li>
                                                </form>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-10 ms-auto">
                                        <div className="header_search">
                                            <div className="input-group">
                                                <span type="button" className="input-group-text">
                                                    <img src="assets/images/icon/search.svg" alt="search" />
                                                </span>
                                                <input id="asearch" type="text" className="form-control" placeholder={t('search')}
                                                    aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="quote_table table-responsive">
                                    <div className="first-container col-sm-12 pull-left">
                                        <table className="table table-hover table-condensed mb-0">
                                            <thead key="thead">
                                                <tr className="table_header_bg">
                                                    <th scope="col">{t('your_reference')} <img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                                    <th scope="col">{t('our_reference')} <img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                                    <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                            alt="filter" /> </th>
                                                    <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                            alt="filter" /> </th>
                                                    <th scope="col">{t('destination')} <img
                                                            src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                                    <th scope="col">{t('status')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                            alt="filter" /> </th>
                                                    <th scope="col" className="action_w_0">Actions</th>
                                                </tr>
                                            </thead>
                                            {data.length > 0 ?
                                                <tbody key="tbody">
                                                    {data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((alertdata, index) => {
                                                        return(
                                                            <>
                                                                <tr className="sub-container" key={index}>
                                                                    <td> 
                                                                        {alertdata.cargo_type == "Air" ?
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="air" />
                                                                        : alertdata.cargo_type == "Sea" ?
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-2" alt="sea" />
                                                                        :
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-2" alt="truck" />
                                                                        }
                                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/right-arrow.svg"} className="exploder" id="arrow" onClick={() => arrowDown}
                                                                            alt="arrow" />{alertdata.our_reference}
                                                                    </td>
                                                                    <td>{alertdata.your_reference}</td>
                                                                    <td>
                                                                        {alertdata.date}
                                                                    </td>
                                                                    <td>{alertdata.origin}</td>
                                                                    <td>{alertdata.destination}</td>
                                                                    <td>
                                                                        {alertdata.status == "Review Invoice" ?
                                                                            <span className="table_respond inhe_table">{alertdata.status}</span>
                                                                        : alertdata.status == "Missing Document" ?
                                                                            <span className="table_pending inhe_table">{alertdata.status}</span>
                                                                        :
                                                                            <span className="table_expire inhe_table">{alertdata.status}</span>
                                                                        }
                                                                    </td>
                                                                    <td className="d-flex align-items-center">
                                                                        <p className="mb-0 me-2 text-start">
                                                                            <a href="/#" className="btn_view w-auto"> <img
                                                                                    src={process.env.PUBLIC_URL +"/assets/images/icon/edit-white.svg"} alt="Edit" /> {t('edit')}</a>
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                    {/* <tr className="sub-container">
                                                        <td> 
                                                            <img src="assets/images/icon/air-blue.svg" className="me-1" alt="air" /> 
                                                            <img src="assets/images/icon/right-arrow.svg" className="exploder" id="arrow" onclick="arrowDown()"
                                                                alt="arrow" />Lorem
                                                        </td>
                                                        <td>Ipsum</td>
                                                        <td>
                                                            14/04/2022
                                                        </td>
                                                        <td>South Letitiaberg</td>
                                                        <td>New Kelvinshire</td>
                                                        <td> <span className="table_respond inhe_table">Review invoice</span> </td>
                                                        <td className="d-flex align-items-center">
                                                            <p className="mb-0 me-2 text-start">
                                                                <a href="/#" className="btn_view w-auto"> <img
                                                                        src="assets/images/icon/edit-white.svg" alt="Edit" /> Edit</a>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr className="explode hide">
                                                        <td colspan="7" style={{ display: "none" }} className="pb-0 b_none">
                                                            <table className="table table-condensed mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pt-0 pb-0 b_none">
                                                                            <ul className="mb-0">
                                                                                <li className="mandate"> <a className="text-start"
                                                                                        href="shipment-tab.html">
                                                                                        Missing Custom Mandate </a></li>
                                                                                <li className="mandate"> <a href="/#" className="text-start">
                                                                                        Missing VAT
                                                                                        number</a></li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="folder_image m-auto text-center">
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                            </div>
                                                            <div className="folder_image m-auto text-center">
                                                                <p className="folder_grey">{t('no_data')}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>

                                </div>
                                <div className="quote_pagination">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-end">
                                            <TablePagination 
                                                className='page-item quote_pre'
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={data.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                            {/* <li className="page-item">
                                                <a className="page-link quote_pre" href="/#">Previous</a>
                                            </li>
                                            <li className="page-item"><a className="page-link active" href="/#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="/#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="/#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link quote_pre" href="/#">Next</a>
                                            </li> */}
                                        </ul>
                                    </nav>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <div className="modal" id="exampleModal3" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered request_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4>Alerts and Tasks</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul>
                                        <li className="mandate"> <a href="shipment-tab.html"> Missing Custom Mandate </a></li>
                                        <li className="mandate"> <a href="/#"> Missing VAT number</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script>
                    {
                        `
                        
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}

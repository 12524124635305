import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { editProfile, feedback, getAbout, getFeedback, getPolicy, modifyPassword, showProfile } from '../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useStorage from "../../hooks/useStorage.ts";
import { useTranslation } from "react-i18next";
import { logOutRedirectCall } from '../Common/RedirectPathMange';
import ToastMessage from '../../components/Toast/index';
import PhoneInput,{ isValidPhoneNumber }  from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const MyAccount = () => {
    const { t, i18n } = useTranslation();

    const [profileData, setProfileData] = useState([]);
    const [feedbackData, setFeedbackData] = useState([]);

    const [aboutDataEng, setAboutDataEng] = useState("");
    const [aboutDataFr, setAboutDataFr] = useState("");
    const [privacyDataEng, setPrivacyDataEng] = useState("");
    const [privacyDataFr, setPrivacyDataFr] = useState("");
    const [termsDataEng, setTermsDataEng] = useState("");
    const [termsDataFr, setTermsDataFr] = useState("");

    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");
    const [num, setNum] = useState('');
    const [Onum, setONum] = useState('');

    var data = JSON.parse(localStorage.getItem("userDetail"))

    const commonProps = {title: "account"}

    const navigate = useNavigate();

    const { setItem, getItem } = useStorage();

    const handleChange4 = event => {
        setNum(event);
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .notRequired().min(3).matches('^[a-zA-Z]', 'Only alphabets are allowed for this field'),
        last_name : Yup.string()
            .notRequired().min(3).matches('^[a-zA-Z]', 'Only alphabets are allowed for this field'),
        email : Yup.string()
            .notRequired()
            .email('Email address is invalid'),
        phone_number : Yup.string()
            .notRequired()
            .typeError('you must specify a number')
            .min(10, 'Phone number must be at least 10 characters'),
        office_phone : Yup.string()
    });

    const feedbackValidationSchema = Yup.object().shape({
        feedback_ques: Yup.string()
            .required('Question field is required.')
            .min(10, 'Question field must be at least 10 characters')
    });

    const changePasswordValidationSchema = Yup.object().shape({
        old_password: Yup.string()
            .required('Old Password is required')    
            .min(6, 'Old Password must be at least 6 characters'),
        new_password: Yup.string()
            .required('New Password is required')    
            .min(6, 'New Password must be at least 6 characters'),
        confirm_password: Yup.string()
            .required('Confirm password is required')
            .min(6, 'Confirm Password must be at least 6 characters')
            .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const feedbackFormOptions = { resolver: yupResolver(feedbackValidationSchema) };
    const changePasswordFormOptions = { resolver: yupResolver(changePasswordValidationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const {register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, reset: feedbackReset} = useForm(feedbackFormOptions);
    const {register: register3, formState: { errors: errors3 }, handleSubmit: handleSubmit3, reset: changePassReset} = useForm(changePasswordFormOptions);

    const { errors } = formState;

    const onSubmit = (data) => {
        var a = data.phone_number;
        data.country_code = a.split(" ")[0];
        data.phone = a.replace(a.split(" ")[0], "").replace(/ /g, '');
        var b = document.getElementById("office_phone_var").value;
        console.log('b: ', b);
        data.office_country_code = b.split(" ")[0];
        data.office_phone = b.replace(b.split(" ")[0], "").replace(/ /g, '');
        editProfile(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                ToastMessage({type:"success", message: response.message});
                navigate("/");
                setItem('userDetail', JSON.stringify(response.data));
            }
            else
            {
                ToastMessage({type:"error", message: response.message});
            }
        });
    }

    const onFeedbackSubmit = (data) => {
        feedback(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                ToastMessage({type:"success", message: response.message});
            }
            else
            {   
                ToastMessage({type:"error", message: response.message});
            }
            feedbackReset();
        });
    }

    const onChangePasswordSubmit = (data) => {
        modifyPassword(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                logOutRedirectCall();
            }
            else
            {   
                ToastMessage({type:"error", message: response.message});
            }
        });
        changePassReset();
    }

    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }

    const togglePassword1 =()=>{
        if(passwordType1==="password")
        {
         setPasswordType1("text")
         return;
        }
        setPasswordType1("password")
    }

    const togglePassword2 =()=>{
        if(passwordType2==="password")
        {
         setPasswordType2("text")
         return;
        }
        setPasswordType2("password")
    }

    useEffect(() => {
        showProfile().then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setONum(`+` + `${response.data.office_country_code}` + `${response.data.office_phone}`)
                setNum(`+` + `${response.data.country_code}` + `${response.data.phone}`)
                setProfileData(response.data);
            }
            else{
                console.log("null");
            }
        });

        getAbout().then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setTermsDataEng(response.data[0].about_eng);
                setTermsDataFr(response.data[0].about_fr);
                setAboutDataEng(response.data[1].about_eng);
                setAboutDataFr(response.data[1].about_fr);
                setPrivacyDataEng(response.data[2].about_eng);
                setPrivacyDataFr(response.data[2].about_fr);
            }
            else{
                console.log("null");
            }
        });

        getFeedback().then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setFeedbackData(response.data);
            }
            else{
                console.log("null");
            }
        });
    }, []);
    
    return (
        <>
            <Helmet>
                <title>{t('account')}</title>
            </Helmet>
            <main className="dash_b h-100">
                <div className="container-fluid h-100">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper" className="h-100">
                            <Topbar />

                            <section className="dashboard_area h-100">
                            <ToastContainer />
                                <div className="main_my_account h-100">
                                    <div className="row h-100">
                                        <div className="col-xl-4 align-items-start">
                                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                                aria-orientation="vertical">
                                                <button className="nav-link active d-flex align-items-center" id="v-pills-home-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                                                    role="tab" aria-controls="v-pills-home" aria-selected="true"> <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/edit-profile.svg"} alt="edit" className="me-2" /> {t('edit_profile')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/right-arrow.svg"} className="ms-auto"
                                                        alt="arrow" /> </button>

                                                <button className="nav-link d-flex align-items-center" id="v-pills-messages-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button"
                                                    role="tab" aria-controls="v-pills-messages" aria-selected="false"> <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/change-password.svg"} alt="key" className="me-2" />
                                                    {t('change_password')} <img src="assets/images/icon/right-arrow.svg" className="ms-auto"
                                                        alt="arrow" /> </button>

                                                <button className="nav-link d-flex align-items-center" id="v-pills-privacy-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-privacy" type="button"
                                                    role="tab" aria-controls="v-pills-privacy" aria-selected="false"> <img
                                                        src="assets/images/icon/privacy.svg" alt="privacy" className="me-2" /> Privacy
                                                    <img src="assets/images/icon/right-arrow.svg" className="ms-auto" alt="arrow" />
                                                </button>

                                                <button className="nav-link d-flex align-items-center" id="v-pills-about-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-about" type="button"
                                                    role="tab" aria-controls="v-pills-about" aria-selected="false"> <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/about-us.svg"} alt="about" className="me-2" /> {t('about_us')}
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/right-arrow.svg"} className="ms-auto" alt="arrow" />
                                                </button>

                                                <button className="nav-link d-flex align-items-center" id="v-pills-terms-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-terms" type="button"
                                                    role="tab" aria-controls="v-pills-terms" aria-selected="false"> <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/terms-and-conditon.svg"} alt="terms"
                                                        className="me-2" /> {t('terms_conditions')} <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/right-arrow.svg"} className="ms-auto" alt="arrow" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="col-xl-8 justify-content-center">
                                            <div className="tab-content w-100" id="v-pills-tabContent">
                                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                    aria-labelledby="v-pills-home-tab">
                                                    <div className="about_text_3">
                                                        <h4>{t('edit_profile')}</h4>
                                                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                                            <div className="about_text">
                                                                <div className="input_box input_box_margin">
                                                                    <input type="text" className="form-control" {...register('first_name')} autoComplete="off" id="exampleInputEmail1" defaultValue={data.first_name}
                                                                        aria-describedby="emailHelp" required="required" />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('first_name')}</label>
                                                                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                                                                </div>
                                                                <div className="input_box input_box_margin">
                                                                    <input type="text" className="form-control" {...register('last_name')} autoComplete="off" id="exampleInputEmail1" defaultValue={data.last_name}
                                                                        aria-describedby="emailHelp" required="required" />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('last_name')}</label>
                                                                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                                                                </div>
                                                                <label htmlFor="exampleInputEmail1" style={{color: "#7CB4B4", fontSize: "13px"}} className="form-label">{t('phone_number')}</label>
                                                                <div className="input_box input_box_margin">
                                                                        <PhoneInput
                                                                            international
                                                                            // defaultCountry='FR'
                                                                            {...register('phone_number')}
                                                                            name="phone_number"
                                                                            value={num}
                                                                            onChange={(e) => setNum(e)}
                                                                            error={num ? (isValidPhoneNumber(num) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                                        />
                                                                    <div className="invalid-feedback">{errors.phone_number?.message}</div>
                                                                </div>
                                                                {/* <div className="input_box input_box_margin">
                                                                    <input type="text" className="form-control" {...register('office_phone')} autoComplete="off" id="exampleInputoffice_phone" defaultValue={data.office_phone}
                                                                        aria-describedby="emailHelp" required="required" />
                                                                    <label htmlFor="exampleInputoffice_phone" className="form-label">{t('office_phone')}</label>
                                                                    <div className="invalid-feedback">{errors.office_phone?.message}</div>
                                                                </div> */}
                                                                <label htmlFor="exampleInputEmail1" style={{color: "#7CB4B4", fontSize: "13px"}} className="form-label">{t('phone_number')}</label>
                                                                <div className="input_box input_box_margin">
                                                                        <PhoneInput
                                                                            international
                                                                            id="office_phone_var"
                                                                            {...register('office_phone')}
                                                                            name="phone_number"
                                                                            value={Onum}
                                                                            onChange={(e) => setONum(e)}
                                                                            error={Onum ? (isValidPhoneNumber(Onum) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                                        />
                                                                    <div className="invalid-feedback">{errors.office_phone?.message}</div>
                                                                </div>
                                                                <div className="input_box">
                                                                    <input type="email" className="form-control" {...register('email')} autoComplete="off" id="exampleInputEmail1" defaultValue={data.email}
                                                                        aria-describedby="emailHelp" required="required" />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('email_address')}</label>
                                                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                                                </div>
                                                                <div className="row justify-content-center">
                                                                    <div className="col-10 col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                                                        <div className="signin_button account_sign_in">
                                                                            <button type='submit' className="btn btn_signin w-100">{t('save')}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                {/* <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                                    aria-labelledby="v-pills-profile-tab">
                                                    <div className="about_text_2">
                                                        <div className="request_select">
                                                            <h4>Select language</h4>
                                                            <select className="form-select" aria-label="Default select example">
                                                                <option selected="">English</option>
                                                                <option value="1">French</option>
                                                                <option value="2">German</option>
                                                                <option value="3">Irish</option>
                                                            </select>
                                                            <div className="row justify-content-center">
                                                                <div className="col-4 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                                    <div className="signin_button account_sign_in">
                                                                        <a href="/#" className="btn btn_signin w-100">Save</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
                                                    aria-labelledby="v-pills-messages-tab">
                                                    <div className="about_text_3">
                                                        <h4>{t('change_password')}</h4>
                                                        <form onSubmit={handleSubmit3(onChangePasswordSubmit)} noValidate>
                                                            <div class="mobile_number_input mb-4">
                                                                <div class="input-group">
                                                                    <input type={passwordType} class="form-control" required="" {...register3('old_password')} id="old_password" />
                                                                    <span type="button" class="input-group-text text-dark" id="basic-addon1" onClick={() => togglePassword()}>{ passwordType==="password"? <iconify-icon icon="ic:baseline-remove-red-eye"></iconify-icon> : <iconify-icon icon="mdi:eye-off"></iconify-icon> }</span><label for="old_password">Old Password</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors3.old_password?.message}</div>
                                                            </div>
                                                            <div class="mobile_number_input mb-4">
                                                                <div class="input-group">
                                                                    <input type={passwordType1} class="form-control" required="" {...register3('new_password')} id="new_password" />
                                                                    <span type="button" class="input-group-text text-dark" id="basic-addon1" onClick={() => togglePassword1()}>{ passwordType1==="password"? <iconify-icon icon="ic:baseline-remove-red-eye"></iconify-icon> : <iconify-icon icon="mdi:eye-off"></iconify-icon> }</span><label for="new_password">New Password</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors3.new_password?.message}</div>
                                                            </div>
                                                            {/* <div className="input_box">
                                                                <input type="text" className="form-control" {...register3('confirm_password')} id="exampleInputEmail1" autoComplete='off'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">{t('confirm_password')}</label>
                                                                <div className="invalid-feedback">{errors3.confirm_password?.message}</div>
                                                            </div> */}
                                                            <div class="mobile_number_input mb-4">
                                                                <div class="input-group">
                                                                    <input type={passwordType2} class="form-control" required="" {...register3('confirm_password')} id="confirm_password" />
                                                                    <span type="button" class="input-group-text text-dark" id="basic-addon1" onClick={() => togglePassword2()}>{ passwordType2==="password"? <iconify-icon icon="ic:baseline-remove-red-eye"></iconify-icon> : <iconify-icon icon="mdi:eye-off"></iconify-icon> }</span><label for="confirm_password">New Password</label>
                                                                </div>
                                                                <div className="invalid-feedback">{errors3.confirm_password?.message}</div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                <div className="col-10 col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                                                    <div className="signin_button account_sign_in">
                                                                        <button type='submit' className="btn btn_signin w-100">{t('save')}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="v-pills-privacy" role="tabpanel"
                                                    aria-labelledby="v-pills-privacy-tab">
                                                    <div className="about_text_6">
                                                        <h4>{t('privacy')} {t('policy')}</h4>
                                                        <h6 dangerouslySetInnerHTML={{ __html: t('privacy') === "Privacy" ? privacyDataEng : privacyDataFr }}></h6>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="v-pills-about" role="tabpanel"
                                                    aria-labelledby="v-pills-about-tab">
                                                    <div className="about_text_5">
                                                        <h4>{t('about_us')}</h4>
                                                        <h6 dangerouslySetInnerHTML={{ __html: t('about_us') === "About us" ? aboutDataEng : aboutDataFr }}></h6>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="v-pills-terms" role="tabpanel"
                                                    aria-labelledby="v-pills-terms-tab">
                                                    <div className="about_text_6">
                                                        <h4>{t('terms_conditions')}</h4>
                                                        <h6 dangerouslySetInnerHTML={{ __html: t('terms_conditions') === "Terms & conditions" ? termsDataEng : termsDataFr }}></h6>
                                                    </div>
                                                </div>

                                                <div className="tab-pane fade" id="v-pills-faq" role="tabpanel"
                                                    aria-labelledby="v-pills-faq-tab">
                                                    <form onSubmit={handleSubmit2(onFeedbackSubmit)}>
                                                        <div className="about_text_7">
                                                            <div className="about_text_7">
                                                                <h4>{t('feedback')}</h4>
                                                                <div className="about_text_7_textarea">
                                                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">{t('report_problem')}</label>
                                                                    <textarea className="form-control" {...register2('feedback_ques')} id="exampleFormControlTextarea1" rows="1" placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas"></textarea>
                                                                    <div className="invalid-feedback">{errors2.feedback_ques?.message}</div>
                                                                </div>
                                                                <div className="row justify-content-end">
                                                                    <div className="col-4 col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                                        <div className="signin_button">
                                                                            <button type='submit' className="btn btn_signin w-100">{t('submit')}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="about_text_7_help">
                                                                    <p className="mb-4">{t('help')}</p>
                                                                    {feedbackData && feedbackData.map((feedbackData, key) => {
                                                                        return(
                                                                            <>
                                                                                <p className="about_text_7_help_p"><span>Q.{key+1}</span> {feedbackData.feedback_ques}</p>
                                                                                <p><span>{t('ans')}.</span> {feedbackData.feedback_ans}</p>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}
export default MyAccount;
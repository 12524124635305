import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { contactUs, getDocuments, getShipmentDetail, getShipmentInvoiceList } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TablePagination } from '@material-ui/core';
import ToastMessage from '../../components/Toast';

export default function Shipment_tab() {
    const commonProps = {title: "shipment"}
    let { id } = useParams();
    
    var [data, setData] = useState([]);
    var [isDocument, setIsDocumentData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [invoiceData, setInvoiceData] = useState(null);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        shipmentDetailFun();
        documentDataFun();
        invoiceDetialsFun();
    }, []);
    
    // For shipment details
    const shipmentDetailFun = () => {
        getShipmentDetail({"shipment_id": id}).then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data[0]);
            }
        });
    }

    // For List down Documents
    const documentDataFun = () => {
        getDocuments({"shipment_id": id}).then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1){
                setIsDocumentData(response.data);
            }else{
                setIsDocumentData([]);
            }
        });
    }

    // For invoice details
    const invoiceDetialsFun = () => {
        getShipmentInvoiceList({"shipment_id": [id]}).then((response)=>{
            response = JSON.parse(response);
            console.log('response: ', response);
            if(response.code == 1){
                setInvoiceData(response.data);
            }else{
                setInvoiceData([]);
            }
        });
    }

    const validationSchema = Yup.object().shape({
        full_name: Yup.string()
            .required(`${t('full_name')} ${t('is_required')}.`),
        email: Yup.string()
            .email(`${t('email_address')} ${t('is_invalid')}`)
            .required(`${t('email_address')} ${t('is_required')}`),
        phone : Yup.number()
            .required(`${t('phone_number')} ${t('is_required')}`)
            .typeError(`${t('phone_number')} ${t('is_invalid')}`)
            .min(10, `${t('phone_number')} must be at least 10 characters`),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        contactUs(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1){
                ToastMessage({type:"success", message: response.message});
            }
            else{   
                ToastMessage({type:"error", message: response.message});
            }
            reset();
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const timeConvert = (time) => {
        var utcTime = moment.utc(time).toDate();
        return moment(utcTime).local().format('DD/MM/YYYY');
    }

    if (isDocument == null) return <></>
    if (data == null) return <></>
    if (invoiceData == null) return <></>

    return (
        <>
            <Helmet>
                <title>Shipment Tab</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />
                            <ToastContainer />
                            <section className="dashboard_area">
                                <div className="ship_all_details_2">
                                    <div className="ship_all_details_tab">
                                        <div className="ship_all_details">
                                            <div className="back_shipment back_shipment_3">
                                                <Link to="/shipment">
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                </Link>
                                                <h4>{t('back')}</h4>
                                            </div>
                                            <ul className="nav nav-tabs justify-content-around" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                                                        data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                        aria-selected="true">{t('summary')}</button>
                                                </li>
                                                {/* <li className="nav-item" role="presentation">
                                                    <button className="nav-link position-relative" id="details-tab"
                                                        data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab"
                                                        aria-controls="details" aria-selected="false">
                                                        {t('details')}
                                                        <div className="profile_notification_number">
                                                            <span className="text-white font_700 f_10">1</span>
                                                        </div>
                                                    </button>
                                                </li> */}
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                        data-bs-target="#profile" type="button" role="tab"
                                                        aria-controls="profile" aria-selected="false">{t('tracking')}</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link position-relative" id="contact-tab"
                                                        data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab"
                                                        aria-controls="contact" aria-selected="false">Documnets
                                                        {/* <div className="profile_notification_number">
                                                            <span className="text-white font_700 f_10">1</span>
                                                        </div> */}
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link position-relative" id="invoice-tab"
                                                        data-bs-toggle="tab" data-bs-target="#invoice" type="button" role="tab"
                                                        aria-controls="invoice" aria-selected="false">{t('invoice')}
                                                        {/* <div className="profile_notification_number">
                                                            <span className="text-white font_700 f_10">1</span>
                                                        </div> */}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content" id="myTabContent">
                                            {/* Summary */}
                                            <div className="tab-pane fade show active" id="home" role="tabpanel"
                                                aria-labelledby="home-tab">
                                                <div className="ship_all_details quote_detail_white_3">
                                                    <h5>{t('references')}:</h5>
                                                    <div className='request_hr'></div>
                                                    <div className="row">
                                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="d-flex align-items-center mt-2">
                                                                <h6 className=" me-3 mb-0">{t('your_reference')}:</h6>
                                                                <span className="xx_blue">{data?.customer_reference}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mt-3">
                                                                <h6 className=" me-3 mb-0">{t('awb')}:</h6>
                                                                <span className="xx_blue">{data.mawb ? data.mawb : data.mbl ? data.mbl : data.ref}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="d-flex mt-3">
                                                                <h6 className=" me-3 mb-0">{t('ISL_referance')}:</h6>
                                                                <div className="d-flex flex-column">
                                                                    <span className="xx_blue">{data?.our_reference}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ship_all_details">
                                                    <h5>{t('itinerary')}:</h5>
                                                    <div className='request_hr'></div>
                                                    <div className="order_shipped ship_grey">
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"><img src={process.env.PUBLIC_URL +"/assets/images/icon/building.svg"} alt="" /></span>
                                                            <p>{t('shipper')}<br /><span>Hyperlink Infosystem, Gandhinagar Highway Opp Gujarat high court, Ahmedabad, Gujarat 380061, India</span></p>
                                                        </div>
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"><img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="" /></span>
                                                            {/* <p>Airport of departure<br /><span>Ahmedabad airport</span></p> */}
                                                        </div>
                                                        <div className="order-tracking completed">
                                                            <span className="is-complete"><img src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="" /></span>
                                                            <p>Airport of arrival<br /><span>Charles de Gaulle airport</span></p>
                                                        </div>
                                                        {/* <div className="order-tracking completed">
                                                            <span className="is-complete"><img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="" /></span>
                                                            <p>Delivery Address<br /><span>ISL CARGO, 385 rue de la belle Etoile 95700 Roissy en France</span></p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="ship_all_details">
                                                    <div className="row">
                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-3 col-sm-3">
                                                            <h5>{t('your_cargo')}:</h5>
                                                        </div>
                                                        <div className="col-12 col-xl-8 col-lg-8 col-md-9 col-sm-9">
                                                            <div className='row'>
                                                                <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                                    <h5 className="me-2">{t('detailed_dimension')}</h5>
                                                                </div>
                                                                {/* <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                                        <span className="switch_dimension">
                                                                            <input type="checkbox" id="switcher" checked="" />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                                        <span className="switch_detail">
                                                                            <input type="checkbox" id="switcher" checked="" />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='request_hr'></div>
                                                    </div>
                                                    <div className="widget_5">
                                                        <div className="row justify-content-center align-items-center text-center">
                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-3 col-sm-3">
                                                                <div className="widget_image">
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/page-image/Pallets.png"} alt="Pallets" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-xl-8 col-lg-8 col-md-9 col-sm-9">
                                                                <div className="quote_table table-responsive">
                                                                    <table className="table table-hover mb-0">
                                                                        <thead>
                                                                            <tr className="table_header_bg">
                                                                                <th scope="col">{t('type')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                                <th scope="col" className="text-center">{t('number')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                                <th scope="col" className="text-center">{t('length')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                                <th scope="col" className="text-center">{t('width')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                                <th scope="col" className="text-center">{t('height')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                                <th scope="col" className="text-center">{t('weight')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {data && data?.summary_data?.cargo_details?.map((item, index) => {
                                                                                return(
                                                                                    <tr key={index}>
                                                                                        <td>{item.package_name}</td>
                                                                                        <td className="text-center">{item.number}</td>
                                                                                        <td className="text-center">{item.length}</td>
                                                                                        <td className="text-center">{item.width}</td>
                                                                                        <td className="text-center">{item.height}</td>
                                                                                        <td className="text-center">{item.weight}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="box_shd total_cost_box">
                                                                            <div className="gross_position mb-1">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/kg.png"} className="img-fluid" style={{width: "35px", height: "35px"}} />
                                                                            </div>
                                                                            <span className="agree_privacy">{data.gross_weight + " kg"}</span>
                                                                            <p className="mb-0 text-start">{t('total_gross_weight')}</p>
                                                                        </div>
                                                                        <div className="box_shd total_cost_box">
                                                                            <div className="gross_position mb-1">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/charge_weight.png"} className="img-fluid" style={{width: "35px", height: "35px"}} alt="weightmeter" />
                                                                            </div>
                                                                            <span className="agree_privacy">{data.gross_weight > data.volume ? data.gross_weight : data.volume  + " gm"}</span>
                                                                            <p className="mb-1 me-2">{t('total_chargeable_weight')}</p>

                                                                        </div>
                                                                        <div className="box_shd total_cost_box">
                                                                            <div className="gross_position mb-1">
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/volume.png"} className="img-fluid" style={{width: "35px", height: "35px"}} alt="weightmeter" />
                                                                            </div>
                                                                            <span className="agree_privacy">{data.volume + " kg"}</span>
                                                                            <p className="mb-0">{t('total_volume')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ship_all_details">
                                                    <div className="d-flex align-items-center mt-3">
                                                        <h5>{t('good_description')}:</h5>
                                                    </div>
                                                    <div className='request_hr'></div>
                                                    <div className="widget_goods d-flex">
                                                        <div className="ms-3">
                                                            <p>{data?.summary_data?.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="tab-pane fade" id="details" role="tabpanel"
                                                aria-labelledby="details-tab">
                                                <div className="co_deta">
                                                    <div className="row">
                                                        <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                            <h5>{t('contact_details')}:</h5>
                                                        </div>
                                                        <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                                                <div className="co_deta_input">
                                                                    <div className="input_box input_box_margin">
                                                                        <input type="text" className="form-control" {...register('full_name')} autoComplete="off"
                                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                            required="required" />
                                                                        <label htmlFor="exampleInputEmail1" className="form-label">{t('full_name')}</label>
                                                                        <div className="invalid-feedback">{errors.full_name?.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="co_deta_input">
                                                                    <div className="input_box input_box_margin">
                                                                        <input type="email" className="form-control" {...register('email')} autoComplete="off"
                                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                            required="required" />
                                                                        <label htmlFor="exampleInputEmail1" className="form-label">{t('email_address')}</label>
                                                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="co_deta_input">
                                                                    <div className="input_box">
                                                                        <input type="text" className="form-control" {...register('phone')} autoComplete="off"
                                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                            required="required" />
                                                                        <label htmlFor="exampleInputEmail1" className="form-label">{t('phone_number')}</label>
                                                                        <div className="invalid-feedback">{errors.phone?.message}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab_green_button d-flex justify-content-around">
                                                                    <div className="signin_button">
                                                                        <button type='submit' className="btn btn_quote_save btn_signin">{t('save')}</button>
                                                                    </div>
                                                                    <div className="signin_button">
                                                                        <button type='reset' className="btn btn_quote_save btn_signin">{t('cancel')}</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* Tracking */}
                                            <div className="tab-pane fade" id="profile" role="tabpanel"
                                                aria-labelledby="profile-tab">
                                                <div className="ship_all_details quote_detail_white_3">
                                                    <h5>{t('references')}:</h5>
                                                    <div className='request_hr'></div>
                                                    <div className="row">
                                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="d-flex align-items-center mt-3">
                                                                <h6 className=" me-3 mb-0">{t('your_reference')}:</h6>
                                                                <span className="xx_blue">{data?.customer_reference}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mt-3">
                                                                <h6 className=" me-3 mb-0">{t('awb')}:</h6>
                                                                <span className="xx_blue">{data.mawb ? data.mawb : data.mbl ? data.mbl : data.ref}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="d-flex mt-3">
                                                                <h6 className=" me-3 mb-0">{t('ISL_referance')}:</h6>
                                                                <div className="d-flex flex-column">
                                                                    <span className="xx_blue">{data?.our_reference}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6">
                                                        <div className="root_body pt-0">
                                                            <div className="root">
                                                                <div className="back_shipment">
                                                                    <h4>{t('status_history')}</h4>
                                                                </div>
                                                                <div className="order-track">
                                                                    {data && data?.tracking_data?.map((item, index)=>{
                                                                        return (
                                                                            <div className="order-track-step" key={index}>
                                                                                <div className="order-track-status">
                                                                                    <span className="order-track-status-dot-30 order-track-status-dot"><img src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="" /></span>
                                                                                    <span className="order-track-status-line"></span>
                                                                                </div>
                                                                                <div className="order-track-text">
                                                                                    <p className="order-track-text-stat">{item?.status}</p>
                                                                                    <span className="order-track-text-sub order-track-text-sub-a4">{timeConvert(item.date)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-6">
                                                        <div className="tracking_map">
                                                            <iframe
                                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59852284.94301916!2d62.39489629287673!3d23.708352605201135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sin!4v1665475652102!5m2!1sen!2sin"
                                                                title="map" width="100%" height="100%" style={{ border:0 }} allowFullScreen=""
                                                                loading="lazy"
                                                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Documents */}
                                            <div className="tab-pane fade" id="contact" role="tabpanel"
                                                aria-labelledby="contact-tab">
                                                <div className="ship_all_details  quote_detail_white_3">
                                                    <h5>{t('references')}:</h5>
                                                    <div className='request_hr'></div>
                                                    <div className="row">
                                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="d-flex align-items-center mt-3">
                                                                <h6 className=" me-3 mb-0">{t('your_reference')}:</h6>
                                                                <span className="xx_blue">{data?.customer_reference}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center mt-3">
                                                                <h6 className=" me-3 mb-0">{t('awb')}:</h6>
                                                                <span className="xx_blue">{data.mawb ? data.mawb : data.mbl ? data.mbl : data.ref}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="d-flex mt-3">
                                                                <h6 className=" me-3 mb-0">{t('ISL_referance')}:</h6>
                                                                <div className="d-flex flex-column">
                                                                    <span className="xx_blue">{data?.our_reference}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="quote_table table-responsive">
                                                    <table className="table table-hover mb-0">
                                                        <thead>
                                                            <tr className="table_header_bg">
                                                                <th scope="col">Documnets <img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col">{t('name_of_file')} <img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col">{t('data_uploaded')} <img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                {/* <th scope="col">{t('status')}</th> */}
                                                                <th scope="col">Actions</th>
                                                                <th scope="col">{t('last_updated')}
                                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                            </tr>
                                                        </thead>
                                                        {data && data?.document_data?.length > 0 ?
                                                            <tbody>
                                                                {data && data?.document_data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((docData, key) => {
                                                                    return(
                                                                        <>
                                                                            {docData.visiblity == "Hidden" ?
                                                                                <></>
                                                                            :
                                                                                <tr key={key}>
                                                                                    {/* data-bs-toggle="modal" data-bs-target="#exampleModalPic" */}
                                                                                    <td>
                                                                                        {docData.document}
                                                                                    </td>
                                                                                    <td>{docData.file_name}</td>
                                                                                    <td>{timeConvert(docData.insertdate)}</td>
                                                                                    {/* <td>
                                                                                        {docData.status == "Confirmed" ?
                                                                                            <span className="table_respond inhe_table">{t('confirmed')}</span>
                                                                                        : docData.status == "Pending" ?
                                                                                            <span className="table_pending inhe_table">{t('pending')}</span>
                                                                                        :
                                                                                            <span className="table_expire inhe_table">{t('missing')}</span>
                                                                                        }
                                                                                    </td> */}
                                                                                    <td>
                                                                                        {/* <a type='button' className="btn_view d-inline-block"> 
                                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/edit-white.svg"} alt="Edit" /> {t('edit')}
                                                                                        </a>
                                                                                        <a type='button' className="btn_view d-inline-block"> 
                                                                                            <i className="fa-solid fa-arrow-up-from-bracket me-1"></i> Upload
                                                                                        </a> */}
                                                                                        <a type='button' className="btn_view d-inline-block">
                                                                                            <i className="fa-solid fa-download"></i> {t('download')}
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        {timeConvert(docData.updated_at)}
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="6">
                                                                        <div className="folder_image m-auto text-center">
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                                        </div>
                                                                        <div className="folder_image m-auto text-center">
                                                                            <p className="folder_grey">{t('no_data')}</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                                <div className="quote_pagination">
                                                    {/* <div className="ms-3">
                                                        <a type='button' className="btn_view"> <i className="fa-solid fa-download"></i>
                                                            {t('download_selected')}</a>
                                                        <a type='button' className="btn_view"> <i className="fa-solid fa-download"></i>
                                                            {t('download_all')}</a>
                                                    </div> */}
                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination justify-content-end">
                                                            <TablePagination 
                                                                className='page-item quote_pre'
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                component="div"
                                                                count={data?.document_data?.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>

                                            {/* Invoice */}
                                            <div className="tab-pane fade" id="invoice" role="tabpanel"
                                                aria-labelledby="invoice-tab">

                                                <div className="row">
                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div className="ship_all_details_4 mt-0">
                                                            <h5>{t('references')}:</h5>
                                                            <div className='request_hr'></div>
                                                            <div className="row justify-content-between">
                                                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    <div className="d-flex align-items-center mt-3">
                                                                        <h6 className=" me-3 mb-0">{t('your_reference')}:</h6>
                                                                        <span className="xx_blue">{data?.customer_reference}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center mt-3">
                                                                        <h6 className=" me-3 mb-0">{t('awb')}:</h6>
                                                                        <span className="xx_blue">{data.mawb ? data.mawb : data.mbl ? data.mbl : data.ref}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                                    <div className="d-flex mt-3">
                                                                        <h6 className="ms-auto me-3 mb-0">{t('ISL_referance')}:</h6>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="xx_blue">{data?.our_reference}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="ship_all_details_4 d-flex justify-content-between align-items-center">
                                                            <h5>{t('invoice')} {t('status')}: <span className="table_respond">Paid</span>
                                                            </h5>
                                                            <a className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a>
                                                        </div> */}
                                                        <div className="quote_table table-responsive">
                                                            <table className="table table-hover mb-0">
                                                                <thead>
                                                                    <tr className="table_header_bg">
                                                                        <th scope="col">{t('our_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col">{t('your_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col">{t('awb')}/BL <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col">{t('invoice_number')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col">{t('amount')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col"> {t('due_date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                                        <th scope="col">{t('status')}</th>
                                                                        {/* <th scope="col" className='action_w_0'>Actions</th> */}
                                                                    </tr>
                                                                </thead>
                                                                {invoiceData.length > 0 ?
                                                                    <tbody>
                                                                        {invoiceData && invoiceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((invoicedata, key) => {
                                                                            return(
                                                                                <>
                                                                                    <tr key={key}>
                                                                                        <td>{invoicedata.our_reference}</td>
                                                                                        <td>{invoicedata.your_reference}</td>
                                                                                        <td>{invoicedata.awb_bl}</td>
                                                                                        <td>{timeConvert(invoicedata.date)}</td>
                                                                                        <td>{invoicedata.invoice_number}</td>
                                                                                        <td>{invoicedata.amount}</td>
                                                                                        <td>{timeConvert(invoicedata.due_date)}</td>
                                                                                        <td> <span className="table_respond">{t('paid')}</span> </td>
                                                                                        {/* <td className="d-flex align-items-center">
                                                                                            <div className="me-1">
                                                                                                <a className="btn_view"> <i className="fa-solid fa-eye"></i> {t('view_shipment')} </a>
                                                                                            </div>
                                                                                            <div className="me-1">
                                                                                                <a className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} </a>
                                                                                            </div>
                                                                                        </td> */}
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                : 
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan="13">
                                                                                <div className="folder_image m-auto text-center">
                                                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                                                </div>
                                                                                <div className="folder_image m-auto text-center">
                                                                                    <p className="folder_grey">{t('no_data')}</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                }
                                                            </table>
                                                        </div>
                                                        <div className="quote_pagination">
                                                            <nav aria-label="Page navigation example">
                                                                <ul className="pagination justify-content-end">
                                                                    <TablePagination 
                                                                        className='page-item quote_pre'
                                                                        rowsPerPageOptions={[5, 10, 25]}
                                                                        component="div"
                                                                        count={invoiceData.length}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={page}
                                                                        onPageChange={handleChangePage}
                                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                                    />
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <div className="modal" id="exampleModal3" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered request_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL +"/assets/images/page-image/table.png"} className="w-100" alt="graph" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal shipment_modal_preview" id="exampleModalPic" tabIndex="-1" aria-labelledby="exampleModalPic Label"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-fullscreen-md-down">
                    <div className="modal-content text-center">
                        <div className="modal-header">
                            <a href="/#">
                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/cancel.svg"} className="img-fluid" alt="close" data-bs-dismiss="modal" aria-label="Close" />
                            </a>
                        </div>
                        <h4 className="mb-3">Preview</h4>
                        <div className="ship_tab_image">
                            <img src={process.env.PUBLIC_URL +"/assets/images/page-image/incoterms.png"} className="img-fluid w-100" alt="incoterms" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

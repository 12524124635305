import axios from "axios";
import CryptoJS from 'crypto-js';
import {logOutRedirectCall, loginRedirectCall} from '../pages/Common/RedirectPathMange'

var key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_SECRET);
var iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

const showMessage = (msg) => {
  console.log(msg)
}

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'api-key' : `qBfWTcXoQw26lXeP2sg3FxLEWkKLDIF4yA0h3HKELJ0=`,
      'accept-language' : 'en',
      'Content-Type' : 'text/plain',
    }
});

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  request.data = bodyEncryption(request.data, true)

    if(localStorage.getItem("authToken") !== undefined || localStorage.getItem("authToken") !== null){
      request.headers['token']=bodyEncryption(JSON.stringify(localStorage.getItem("authToken")), false)
    }
  return request;
});
  
axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data);

    var respData = JSON.parse(response);
    if (respData.code == -1) {
      logOutRedirectCall();
      return;
    }
    if(response.code==0){
      showMessage(response.data.message)
    }
    return response;
  }, 
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      logOutRedirectCall()
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

function bodyEncryption(request, isStringify)
{
    var request = (isStringify) ? JSON.stringify(request) : request;
    var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
    
    return encrypted.toString();
}

function bodyDecryption(request)
{
    var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
    
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export {axiosClient};
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { getTrackingShipmentDetails } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

export default function TrackParcel() {
    const commonProps = {title: "shipment"}

    var [data, setData] = useState([]);

    let { id } = useParams();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getTrackingShipmentDetails({"shipment_id": id}).then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }
            else{
                setData([]);
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('shipment_tracking')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps} />

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="root_body">
                                            <div className="root">
                                                <div className="back_shipment">
                                                    <Link to="/shipment">
                                                        <i className="fa-solid fa-chevron-left"></i>
                                                    </Link>
                                                    <h4>{t('shipment_tracking')}</h4>
                                                </div>
                                                <div className="order-track">
                                                    {data && data.map((item, index) => {
                                                        return(
                                                            <div className="order-track-step">
                                                                <div className="order-track-status">
                                                                    <span
                                                                        className="order-track-status-dot-green order-track-status-dot"></span>
                                                                    <span className="order-track-status-line"></span>
                                                                </div>
                                                                <div className="order-track-text">
                                                                    <p className="order-track-text-stat">{item.status}</p>
                                                                    <span className="order-track-text-sub">{moment(item.date).format('Do MMMM, YYYY')}</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}

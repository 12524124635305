import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { getQuotes, addQuotes, getFilterQuotes } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { parse, isDate } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablePagination from '@material-ui/core/TablePagination';
import { useTranslation } from "react-i18next";
import Select from 'react-select';

export default function Quotes() {
    const commonProps = {title: "quotes"}
    
    var [data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { t, i18n } = useTranslation();
    
    var fileDownload = require('js-file-download');
        
    useEffect(() => {
        filterData({});
    }, []);
    
    function filterData(data){
        getQuotes(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }
        });
    };

    function parseDateString(value, originalValue) {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, "yyyy-MM-dd", new Date());
        return originalValue;
    }

    const validationSchema = Yup.object().shape({
        start_date: Yup.string().transform(parseDateString).notRequired(),
        end_date: Yup.string().transform(parseDateString).notRequired(),
        origin: Yup.string(),
        destination: Yup.string()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    
    const { register, handleSubmit, control, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
       getFilterQuotes (data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }
            else{
                console.log("null");
            }
        });
        reset();
    }

    var optionOrigin = [];
    var optionDestination = [];
    data && data.map((item, index) => {;
        var originObj = {
            label: item.origin,
            value: item.departure_airport_id != '' && item.departure_airport_id != null ? item.departure_airport_id : item.departure_port_id != '' && item.departure_port_id != null ? item.departure_port_id : item.departure_city
        };
        var destinationObj = {
            label: item.destination,
            value: item.arrival_airport_id != '' && item.arrival_airport_id != null ? item.arrival_airport_id : item.arrival_port_id != '' && item.arrival_port_id != null ? item.arrival_port_id : item.arrival_city
        };

        if (!optionOrigin.some(obj => obj.value === originObj.value)) {
            optionOrigin.push(originObj);
        }
        if (!optionDestination.some(obj => obj.label === destinationObj.label)) {
            optionDestination.push(destinationObj);
        }
    })

    let navigate = useNavigate(); 
    const routeChange = (data) =>{
        navigate(`/quotesdetails/${data}`);
    }

    const duplicateData = (data) => {
        addQuotes(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                window.location.reload();
            }
            else{
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        })
    }

    const contractdetails=async(data)=>{
        const contract = await data;
        fileDownload(JSON.stringify(contract), 'Quote.csv');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const timeConvert = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    if (data == null) return <></>
    return (
        <>
            <Helmet>
                <title>{t('quotes')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />
                            <ToastContainer />

                            <section className="dashboard_area">
                                {/* <div className="d-flex">
                                    <div className="ms-md-auto">
                                        <div className="signin_button">
                                            <Link to="/requestquotes4" className="btn dataTable btn_quote_new btn_signin">{t('new_request')}</Link>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row align-items-center quo_area">
                                    <div className="col-12 col-xl-9 col-lg-7 col-md-7 col-sm-12 d-flex ps-0">
                                        <div className="q_drop">
                                            <ul className="quotes_drop quotes_drop_f6 mb-0">
                                                <li className="d-flex align-items-center show_block">
                                                    <h6 className="s_o_padding">{t('show_only')}</h6>
                                                    <span type="button" className="dropdown-item btn btn_quotes" onClick={() => filterData({"transport_mode":"Air"})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} alt="plane" /> {t('air')} <i className="fa-solid fa-circle-check"></i> </span>
                                                    <span type="button" className="dropdown-item btn btn_quotes" onClick={() => filterData({"transport_mode":"Sea"})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="boat" />{t('sea')} <i className="fa-solid fa-circle-check"></i> </span>
                                                    <span type="button" className="dropdown-item btn btn_quotes" onClick={() => filterData({"quote_status":"Responded"})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/responded-blue.svg"} alt="respond" />{t('responded')} <i className="fa-solid fa-circle-check"></i> </span>
                                                    <span type="button" className="dropdown-item btn btn_quotes" onClick={() => filterData({"quote_status":"Pending"})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/pending-blue.svg"} alt="pending" />{t('pending')} <i className="fa-solid fa-circle-check"></i> </span>
                                                    <span type="button" className="dropdown-item btn btn_quotes" onClick={() => filterData({"quote_status":"Expired"})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/expired-blue.svg"} alt="expire" />{t('expired')} <i className="fa-solid fa-circle-check"></i> </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                        <div className="dropdown">
                                            <button className="btn dropdown-toggle text_35 pt-0" data-bs-auto-close="outside" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                {t('filter')} <img src="assets/images/icon/down-arrow.svg" alt="" />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <form onSubmit={handleSubmit(filterData)} noValidate>
                                                    <li>
                                                        <span className="dropdown-item">
                                                            <span>{t('select_date')}</span>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('from')}:</p>
                                                                <input type="date" id="start_date" name="start_date" className="date_input" {...register('start_date')} />
                                                            </div>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('to')}:</p>
                                                                <input type="date" id="end_date" name="end_date" className="date_input" {...register('end_date')} />
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="mt-2">
                                                        <span className="dropdown-item quote_origin">
                                                            <div className="input_box">
                                                                {/* <input type="text" className="form-control" placeholder={t('origin')} {...register('origin')} id="origin" required="required" />
                                                                <label htmlFor="origin" className="form-label"></label> */}
                                                                <Controller
                                                                    name='origin'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={optionOrigin}
                                                                            onChange={(selectedOption) => { field.onChange(selectedOption.value); }}
                                                                            value={optionOrigin.find(option => option.value === field.value)}
                                                                            placeholder="Select Origin"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="mt-2">
                                                        <span className="dropdown-item quote_origin">
                                                            <div className="input_box">
                                                                {/* <input type="text" className="form-control" placeholder={t('destination')} {...register('destination')} id="destination" required="required" />
                                                                <label htmlFor="destination" className="form-label"></label> */}
                                                                <Controller
                                                                    name='destination'
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={optionDestination}
                                                                            onChange={(selectedOption) => { field.onChange(selectedOption.value); }}
                                                                            value={optionDestination.find(option => option.value === field.value)}
                                                                            placeholder="Select Destination"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li className="quote_apply mt-2">
                                                        <button type='submit' className="dropdown-item btn btn_quote_apply">{t('apply')}</button>
                                                    </li>
                                                </form>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-10 ms-auto">
                                        <div className="header_search">
                                            <div className="input-group">
                                                <span type="button" className="input-group-text">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/search.svg"} alt="search" />
                                                </span>
                                                <input id="qsearch" type="text" className="form-control" placeholder={t('search')} onChange={() => filterData({ "search" : document.getElementById("qsearch").value })} aria-label="Recipient's username" aria-describedby="basic-addon2" autoComplete='off' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="quote_table table-responsive">
                                    <table id='mangeUser' className="table table-hover mb-0">
                                        <thead key="thead">
                                            <tr className="table_header_bg">
                                                <th scope="col">{t('your_reference')} <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                                <th scope="col">{t('our_reference')} <img
                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                                <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                        alt="filter" /> </th>
                                                <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                        alt="filter" /> </th>
                                                <th scope="col">{t('destination')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                        alt="filter" /> </th>
                                                <th scope="col">{t('status')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                        alt="filter" /> </th>
                                                <th scope="col" className="action_w_0">Actions</th>
                                            </tr>
                                        </thead>
                                        {data.length > 0 ?
                                            <tbody key="tbody">
                                                {data && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((quotedata, key) => {
                                                    return(
                                                        <tr className="table-row" key={key} onClick={e => {
                                                                if (e.target.id === 'action' || e.target.id === 'editquote' || e.target.id === 'contract') {
                                                                    return;
                                                                }
                                                                else{
                                                                    // routeChange(quotedata._id)
                                                                }
                                                            }}>
                                                            <td>
                                                                {quotedata.transport_mode == "Air" ?
                                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="air" />
                                                                : quotedata.transport_mode == "Sea" ?
                                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-2" alt="sea" />
                                                                :
                                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-2" alt="truck" />
                                                                }
                                                                {quotedata.customer_reference}
                                                            </td>
                                                            <td>{quotedata.our_reference}</td>
                                                            <td>
                                                                {timeConvert(quotedata.date)}
                                                            </td>
                                                            <td>{quotedata.origin}</td>
                                                            <td>{quotedata.destination}</td>
                                                            <td> 
                                                                {quotedata.quote_status == "Expired" ? 
                                                                    <span className="table_expire inhe_table">{t('expired')}</span> 
                                                                : quotedata.quote_status == "Pending" ? 
                                                                    <span className="table_pending inhe_table">{t('pending')}</span> 
                                                                : 
                                                                    <span className="table_respond inhe_table">{t('responded')}</span>
                                                                }
                                                            </td>
                                                            <td className="d-flex align-items-center">
                                                                <p className="mb-0 me-2 text-start">
                                                                    <a id={"action"} type='button' onClick={() => duplicateData(quotedata)} className="btn_view w-100"> <i className="fa-regular fa-clone"></i> {t('duplicate')}</a>
                                                                </p>
                                                                {/* <p className="mb-0 me-2 text-start">
                                                                    <Link id={"editquote"} to={`/editquotes/${quotedata._id}`} className="btn_view w-100"> <img src={process.env.PUBLIC_URL +"/assets/images/icon/edit-white.svg"} alt="Edit" /> {t('edit')}</Link>
                                                                </p> */}
                                                                <p className="mb-0">
                                                                    <a id={"contract"} onClick={() => contractdetails(quotedata)} className="btn_view"> <i className="fa-solid fa-download"></i>{t('download')}</a>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        :
                                            <tbody>
                                                <tr>
                                                    <td colSpan="7">
                                                        <div className="folder_image m-auto text-center">
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                        </div>
                                                        <div className="folder_image m-auto text-center">
                                                            <p className="folder_grey">{t('no_data')}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>
                                <div className="quote_pagination">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-end">
                                            <TablePagination 
                                                className='page-item quote_pre'
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={data.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </ul>
                                    </nav>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TablePagination from '@material-ui/core/TablePagination';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useTranslation } from "react-i18next";
import { getShipmentInvoiceList } from '../../api/apiHandler';
import moment from 'moment/moment';

export default function Invoice() {
    const commonProps = {title: "invoice"}
    const { t, i18n } = useTranslation();

    const [isData, setIsData] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        invoiceList({});
    }, []);

    const invoiceList = (data) => {
        getShipmentInvoiceList(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setIsData(response.data);
            }
            else{
                setIsData([]);
            }
        });
    }

    const timeConvert = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const totalPastDueAmount = () => {
        let sum = 0;
        isData.map((item) => {
            if(moment(item.due_date).format("DD/MM/YYYY") > moment(new Date()).format("DD/MM/YYYY")){
                sum = sum + item.amount
            }
        });
        return sum.toFixed(2).toString().concat(" EUROS");
    };

    const totalPast15DueAmount = () => {
        let sum = 0;
        isData.map((item) => {
            if(moment(new Date()).diff(moment(item.due_date), 'days') > 15){
                sum = sum + item.amount
            }
        });
        return sum.toFixed(2).toString().concat(" EUROS");
    };

    const totalPast5DueAmount = () => {
        let sum = 0;
        isData.map((item) => {
            if(moment(new Date()).diff(moment(item.due_date), 'days') >= 5){
                sum = sum + item.amount
            }
        });
        return sum.toFixed(2).toString().concat(" EUROS");
    };

    const totalDueAmount = () => {
        let sum = 0;
        isData.map((item) => {
            if(moment(item.due_date).format("DD/MM/YYYY") < moment(new Date()).format("DD/MM/YYYY")){
                sum = sum + item.amount
            }
        });
        return sum.toFixed(2).toString().concat(" EUROS");
    };

    const total15DueAmount = () => {
        let sum = 0;
        isData.map((item) => {
            if(moment(new Date()).diff(moment(item.due_date), 'days') <= 15){
                sum = sum + item.amount
            }
        });
        return sum.toFixed(2).toString().concat(" EUROS");
    };

    const total5DueAmount = () => {
        let sum = 0;
        isData.map((item) => {
            if(moment(new Date()).diff(moment(item.due_date), 'days') <= 5){
                sum = sum + item.amount
            }
        });
        return sum.toFixed(2).toString().concat(" EUROS");
    };

    if (isData == null) return <></>

    return (
        <>
            <Helmet>
                <title>{t('invoice')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="main_invoice">
                                    <div className="row">
                                        <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                            <div className="row">
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due" />
                                                        <span className='green_5'>{totalDueAmount()}</span>
                                                        <p>{t('total_amount_due')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-2.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-2" />
                                                        <span className='green_5'>{total15DueAmount()}</span>
                                                        <p>{t('due_in_15_days')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-3.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-3" />
                                                        <span className='green_5'>{total5DueAmount()}</span>
                                                        <p>{t('due_in_5_days')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-4.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-4" />
                                                        <span className="euro_red">{totalPastDueAmount()}</span>
                                                        <p>{t('total_past_due_date')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-6.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-6" />
                                                        <span className="euro_red">{totalPast15DueAmount()}</span>
                                                        <p>{t('past_15_days')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-5.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-5" />
                                                        <span className="euro_red">{totalPast5DueAmount()}</span>
                                                        <p>{t('past_5_days')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-between of_white">
                                            <div className='row'>
                                                <div className='col-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                                    <p className="invoice_blue">{t('statement_of_account')}</p>
                                                </div>
                                                <div className='col-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                        <span className="switch_dimension">
                                                            <input type="checkbox" id="switcher" checked="" />
                                                            <label htmlFor="switcher"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="statement_date">    
                                                <a href="/#" className="btn btn_statement_green">{t('all')}</a>
                                                <a href="/#" className="btn btn_statement_green">{t('current_year')}</a>
                                                <a href="/#" className="btn btn_statement_green">{t('latest')}</a>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="quote_dropdown">
                                                        <p className="mb-0">{t('from')}:</p>
                                                        <input type="date" id="birthday" name="birthday" className="date_input w-100" />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="quote_dropdown">
                                                        <p className="mb-0">{t('to')}:</p>
                                                        <input type="date" id="birthday" name="birthday" className="date_input w-100" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="signin_button signin_button_next">
                                                <a href="/#" className="btn btn_signin_invoice w-100">{t('download_statement')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center quo_area">
                                        <div className="col-xl-4 d-flex">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle text_35" type="button" id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    {t('filter')} <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a className="dropdown-item" href="/#">
                                                            <span>{t('select_date')}</span>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('from')}:</p>
                                                                <input type="date" id="birthday" name="birthday"
                                                                    className="date_input" />
                                                            </div>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('to')}:</p>
                                                                <input type="date" id="birthday" name="birthday"
                                                                    className="date_input" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="mt-2">
                                                        <a className="dropdown-item quote_origin" href="/#">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder={t('origin')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="mt-2">
                                                        <a className="dropdown-item quote_origin" href="/#">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder={t('destination')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="quote_apply mt-2">
                                                        <a className="dropdown-item btn btn_quote_apply" href="/#">{t('apply')}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ul className="quotes_drop quotes_drop_f6 mb-0">
                                                <li className="d-flex align-items-center show_block">
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => invoiceList({"type": ["Air Export", "Air Import"]})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} alt="plane" /> {t('air')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => invoiceList({"type": ["Sea Export", "Sea Import"]})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="boat" />{t('sea')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" onClick={() => invoiceList({"type": ["Miscellaneous"]})}><img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="truck" />{t('other')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes"><img src={process.env.PUBLIC_URL +"/assets/images/icon/responded-blue.svg"} alt="respond" />{t('responded')}<i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes"><img src={process.env.PUBLIC_URL +"/assets/images/icon/pending-blue.svg"} alt="pending" />{t('pending')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes"><img src={process.env.PUBLIC_URL +"/assets/images/icon/expired-blue.svg"} alt="expire" />{t('expired')} <i className="fa-solid fa-circle-check"></i> </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-2 ms-auto">
                                            <div className="header_search">
                                                <div className="input-group">
                                                    <span className="input-group-text">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/search.svg"} alt="search" />
                                                    </span>
                                                    <input type="text" className="form-control" placeholder={t('search')} onChange={(e) => invoiceList({"search" : e.target.value})} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="quote_table table-responsive">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr className="table_header_bg">
                                                    <th scope="col">{t('our_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('your_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('awb')}/BL <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('invoice_number')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('amount')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col"> {t('due_date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('status')}</th>
                                                    <th scope="col" className='action_w_0'>Actions</th>
                                                </tr>
                                            </thead>
                                            {isData.length > 0 ?
                                                <tbody>
                                                    {isData && isData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((invoicedata, key) => {
                                                        return(
                                                            <>
                                                                <tr key={key}>
                                                                    <td>{invoicedata.type.split(" ")[0] == "Air" ? 
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="plane" />
                                                                        : invoicedata.type.split(" ")[0] == "Sea" ?
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-1" alt="ship" />
                                                                        :
                                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-1" alt="truck" />
                                                                        }{invoicedata.our_reference}
                                                                    </td>
                                                                    <td>{invoicedata.your_reference}</td>
                                                                    <td>{invoicedata.awb_bl}</td>
                                                                    <td>{timeConvert(invoicedata.date)}</td>
                                                                    <td>{invoicedata.invoice_number}</td>
                                                                    <td>{invoicedata.amount}</td>
                                                                    <td>{timeConvert(invoicedata.due_date)}</td>
                                                                    <td> <span className="table_respond">{t('paid')}</span> </td>
                                                                    <td className="d-flex align-items-center">
                                                                        <div className="me-1">
                                                                            <a className="btn_view"> <i className="fa-solid fa-eye"></i> {t('view_shipment')} </a>
                                                                        </div>
                                                                        <div className="me-1">
                                                                            <a className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            : 
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="13">
                                                            <div className="folder_image m-auto text-center">
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                            </div>
                                                            <div className="folder_image m-auto text-center">
                                                                <p className="folder_grey">{t('no_data')}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    <div className="quote_pagination">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <TablePagination 
                                                    className='page-item quote_pre'
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    component="div"
                                                    count={isData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </ul>
                                        </nav>
                                    </div>
                                    {/* <div className="quote_pagination d-flex justify-content-between align-items-center">
                                        <div className="ms-3">
                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download_statement')}</a>
                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} {t('all')}</a>
                                        </div>
                                    </div> */}
                                </div>
                            </section>
                        </section>

                    </div>
                </div>
            </main>
        </>
    )
}
